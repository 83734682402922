/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'AccordionWidget',
        import: () => import('@stories/Widgets/AccordionWidget/AccordionWidget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleImage',
        import: () => import('@stories/Widgets/ArticleImage/ArticleImage'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Button',
        import: () => import('@stories/Widgets/Button/Button'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCardWidget',
        import: () => import('@stories/Widgets/CtaCardWidget/CtaCardWidget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EscapeButton',
        import: () => import('@stories/Widgets/EscapeButton/EscapeButton'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'FeaturedProperties',
        import: () => import('@stories/Widgets/FeaturedProperties/FeaturedProperties'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Components/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HomeHeroBanner',
        import: () => import('@stories/Widgets/HomeHeroBanner/HomeHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import('@stories/Widgets/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHeroBanner',
        import: () => import('@stories/Widgets/InnerPageHeroBanner/InnerPageHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'KeyFacts',
        import: () => import('@stories/Widgets/KeyFacts/KeyFacts'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoList',
        import: () => import('@stories/Widgets/LogoList/LogoList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaCarousel',
        import: () => import('@stories/Widgets/MediaCarousel/MediaCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaListing',
        import: () => import('@stories/Widgets/Listings/MediaListing/MediaListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Search',
        import: () => import('@stories/Widgets/Listings/Search/Search'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostCards',
        import: () => import('@stories/Widgets/SignpostCards/SignpostCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SiteFooter',
        import: () => import('@stories/Widgets/Footer/SiteFooter/SiteFooter'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitOverlapSignpostContentCard',
        import: () => import('@stories/Widgets/SplitOverlapSignpostContentCard/SplitOverlapSignpostContentCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitSignpostCarousel',
        import: () => import('@stories/Widgets/SplitSignpostCarousel/SplitSignpostCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitSignpostContentCard',
        import: () => import('@stories/Widgets/SplitSignpostContentCard/SplitSignpostContentCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Table',
        import: () => import('@stories/Widgets/Table/Table'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TeamList',
        import: () => import('@stories/Widgets/TeamList/TeamList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialCarousel',
        import: () => import('@stories/Widgets/TestimonialCarousel/TestimonialCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UsefulDocuments',
        import: () => import('@stories/Widgets/UsefulDocuments/UsefulDocuments'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UspList',
        import: () => import('@stories/Widgets/UspList/UspList'),
        config: {
            hydrate: 'in-view',
        },
    }
];
